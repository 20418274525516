#testimonials {
    padding: 0 32px;

    .container {
        max-width: 800px;
        margin: auto;

        h3 {
            font-size: 1.1rem;
        }

        .card {
            min-height: 450px;
            
            img {
                width: 200px;
                border-radius: 50%;
                margin: 32px 0 16px;
            }

            h3 {
                padding-bottom: 10px;
                font-size: 1.66rem;
                font-weight: 400;
            }

            h4 {
                padding-bottom: 20px;
                font-weight: 400;
            }

            p {
                font-weight: 500;
                padding: 0;
            }
        }

        button {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            border: solid 2px #1ba49c;
            margin: 20px 10px 0;
            padding: 0;
            line-height: 15px;
            transition: all .4s ease-in-out;
        }

        button.active {
            background: #1ba49c;
        }
    }
}
