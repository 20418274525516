#notfound {
    max-width: 1200px;
    margin: auto;
    padding: 32px;
    height: 60vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    &.fade-in-screen {
        opacity: 1;
        transition: all 1s ease;
    }

    h1 {
        span { 
            display: block;
            margin: 10px;
            font-size: 3.25rem;
        }
    }

    .wrap {
        margin-top: 48px;
    }
}