#about {
    padding: 100px 10px 150px;
    background: #1ba49c;
    height: 100%;

    .container {
        max-width: 1000px;
        margin: auto;
    }

    h2, h3, p {
        color: #fff;
    }

    h2 {
        font-size: 2.2rem;
    }

    h3 {
        font-family: 'Europa', 'Montserrat', sans-serif;
        line-height: 1.8;
    }

    p {
        line-height: 1.8;
    }

    @media (max-width: 660px) {
        padding: 50px 10px 50px;
        min-height: 650px;

        h2 { 
            padding-left: 40px;
            padding-right: 40px;
            font-size: 1.5rem;
        }

        h3 {
            line-height: 1.5;
        }
    }
}