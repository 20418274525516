#contact-bar {
    width: 100%;
    padding: 0 20px;

    .content-wrap {
        background: #141C3A;
        max-width: 1200px;
        margin: 0 auto;
        padding: 48px 16px;
        border-radius: 15px;
        position: relative;
        top: 60px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    
        h3 {
            font-size: 2rem;
            font-weight: 700;
            color: #fff;
            padding: 0;
        }

        p {
            padding: 0;
        }
    
        p, .btn {
            color: #fff;
        }
    
        .btn:hover {
            background: #1ba49c;
        }

        @media (max-width: 750px) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            margin-top: -32px;
            p {
                padding: 32px 0 ;
            }
        }
    }
}