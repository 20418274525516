#contact-section {
    max-width: 1200px;
    margin: auto;
    padding: 32px;

    img {
        border-radius: 50%;
        margin: 32px 0;
    }

    .form-wrap {
        width: 100%;
        margin: auto;
        .wrap {
            width: auto;
            max-width: 750px;
            margin: 0 auto;

            div {
                .input {
                    width: 100%;
                }
            }
        }

        .btn {
            margin-top: 20px;
        }

        .message {
            line-height: 1.5;
            max-width: 760px;
            margin: 32px auto 0;
        }
    }
}