.projects {
    padding: 0 20px 32px;
    margin-bottom: 160px;

    .h3 {
        margin-bottom: 32px;
    }

    .email {
        &:hover {
            color: #167470;
        }
    }

    .btn-wrap {
        margin-bottom: 62px;
    }

    ul {
        max-width: 1200px;
        margin: auto;
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-gap: 20px;

        @media (max-width: 350px) {
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        }

        .card {
            height: 320px;
            border-radius: 30px;
            -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            position: relative;
            overflow: hidden;

            img {
                width: 250px; 
                -webkit-transition: .45s ease-in-out; 
                -o-transition: .45s ease-in-out; 
                transition: .45s ease-in-out;
                display: block;
                opacity: 1;
            }

            .content-wrap {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                height: 100%;
                width: 100%;
                text-align: center;
                -webkit-transition: .45s ease-in-out;
                -o-transition: .45s ease-in-out;
                transition: .45s ease-in-out;
                background: #0d4e4a;
                border-radius: 30px;
                
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;

                .text-wrap {
                    height: 70%;
                    p {
                        color: #fff;
                        padding-top: 32px;

                        @media only screen and (max-width: 1200px) {
                            font-size: 18px;
                        }

                        @media only screen and (max-width: 660px) {
                            font-size: 20px;
                        }

                        @media only screen and (max-width: 400px) {
                            font-size: 18px;
                        }
                    }
                }

                .btn-wrap {
                    max-width: 250px;
                    margin: auto;

                    .btn {
                        color: #fff;
                        font-size: 1.1rem;
                    }
                }
            }

            &:hover img {
                opacity: 0;
            }

            &:hover .content-wrap {
                opacity: 1;
            }
    
            &.leysa{
                background: #F0BC68;
            }
            &.sah {
                background-color: #DCCEC8;
                background-image: url('../images/wave-lines.png');
                background-size: cover;
            }
            &.ouidad {
                background-color: #FAE6D8;
            }
            
            &.the {
                background: #F29E66;
                img { max-width: 230px; }
            }
            &.sonna {
                background-color: #58585A;
                background-size: cover;

                img { max-width: 320px; }
            }

            &.johnny {
                background-color: #000;
                background-size: cover;

                img { max-width: 320px; }
            }
            &.ollin {
                background-color: #141313;
                background-size: cover;

                img { max-width: 320px; }
            }
            &.michelle{
                background-color: #000;
                background-image: url('../images/Michelle.png');
                background-size: cover;

                img { max-width: 320px; }
            }
            &.hairboss {
                background: #000;
                img { max-width: 320px; }
            }
            &.ais {
                background: #1F4F6C;
                img { 
                    max-width: 320px;
                    width: 100%; 
                }
            }
            &.spp {
                img { 
                    max-width: 180px;
                    width: 100%; 
                }
            }
            &.yuki {
                background: #000;
                img { 
                    max-width: 280px;
                    width: 100%; 
                }
            }
            &.be {
                background: #A03484;
                img { 
                    max-width: 120px;
                    width: 100%; 
                }
            }
            &.bp {
                background: #024959;
                img { 
                    width: 100%;
                    max-width: 250px
                }
            }
            &.greenhills {
                img {
                    position: relative;
                    bottom: 20px;
                }
            }
            &.rdf {
                img { 
                    width: 100%;
                    max-width: 200px
                }
            }
            &.crossing {
                img { 
                    width: 100%;
                    max-width: 200px
                }
            }
            &.lfi {
                background: #F1F2F5;
                img { 
                    width: 100%;
                    max-width: 180px
                }
            }

            @media (max-width: 750px) {
                img {
                    max-width: 200px;
                }
            }
        }

        @media (max-width: 660px) {
            max-width: 400px;
            margin: auto;
        }
    }

    @media(max-width: 750px) {
        margin-bottom: 128px;

        .h3 {
            margin-bottom: 32px;
        }
    }
}