#results-page {
    #summary {
        padding: 100px 10px 10px;
        background: #1ba49c;
        min-height: 450px;
        height: 100%;

        .container {
            max-width: 1000px;
            margin: auto;
        }

        p {
            color: #fff;
            line-height: 1.8;
        }
    }

    #results {
        margin: -96px auto 80px;
        padding: 0 20px;

        ul {
            max-width: 1200px;
            margin: auto;
            display: -ms-grid;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            grid-gap: 20px;

            li {
                .card {
                    background-color: #fff;
                    -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
                            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
                    border-radius: 30px;
                    padding: 20px 10px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                    -webkit-box-pack: center;
                    min-height: 100%; // keeps heights the same height as tallest element

                    h2 {
                        padding: 0 0 20px;
                    }

                    h3 {
                        font-size: 60px;
                        color: #1ba49c;
                        margin-top: auto;
                    }

                    .btn-wrap {
                        margin-top: auto;
                        margin-bottom: 10px;
                    }

                    .btn-secondary {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    @media (max-width: 1233px) {
        #results {
            max-width: 750px;
        }
    }

    @media (max-width: 900px) {
        #summary {
            padding: 80px 10px 80px;
        }

        #results {
            margin: -60px auto 80px;
        }
    }

    @media (max-width: 620px) {
        #results {
            max-width: 350px;
            margin: -100px auto 80px;
        }
    }

    @media (max-width: 500px) {
        #results {
            margin: -80px auto 80px;
        }
    }
}