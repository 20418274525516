* {
  font-family: 'Europa', 'Montserrat', sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  scroll-behavior: smooth;
}

.app {
  text-align: center;
}

main {
  opacity: 0;
  transition: all ease 1s;
}

h1, h2, h3, h4, h5, ul, p, a {
  margin: 0;
  padding: 0;
}

h1, h2, h3 {
  font-family: 'Eurostile-Bold', 'Montserrat', sans-serif;
}

h2 {
  font-size: 2.25rem;
  font-weight: 700;
  padding: 20px 0;
}

h3 {
  font-size: 1.66rem;
  font-weight: 700;
}

p {
  font-size: 1.2rem;
  font-weight: 300;
  padding: 0 20px 20px;
}

p {
  line-height: 1.5;
}

a {
  color: #1ba49c;
  text-decoration: none;
}

li {
  list-style-type: none;
}

label { 
  font-size: 1.1rem; 
}

input, textarea {
  font-size: 1.1rem;
  margin: 10px 0;
  padding: 10px;
  width: 240px;

  @media(max-width: 1100px) {
      width: 100%;
      margin: 10px auto;
  }
}

textarea {
  width: 380px;
  @media(max-width: 1100px) {
      width: 100%;
  }
}

button {
  background: none;
  outline: unset;
  border: unset;

  &:hover {
    cursor: pointer;
  }
}

.btn {
  border: #1ba49c solid 2px;
  color: #1ba49c;
  border-radius: 100px;
  padding: 8px 28px;
  font-size: 1.1rem;
  font-weight: 500;

  &:hover, &:disabled {
    background: #1ba49c;
    color: #fff;
  }
}

.btn-secondary {
  border: none;
  font-size: 1.1rem;
  padding: 8px 16px;
  color: #fff;
  border-radius: 4px;
  background-color: #06BC9B;
  background-image: -webkit-linear-gradient(left,#06BC9B,#1ba49c);
  cursor: pointer;

  &:hover {
      background-color: #06BC9B;
      background-image: -webkit-linear-gradient(left,#1ba49c,#06BC9B);
      
      i {
          color: #0d4e4a;
      }
  }

  i {
      font-size: .8rem;
      color: #fff;
      margin-left: 10px;
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
  }
}

.error {
  text-align: left;
  padding-left: 10px;
  color: #1ba49c;
}

.fade-in {
  -webkit-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.fade-in-screen {
  opacity: 1;
  transition: all 1s ease;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 20px;

  &.column {
    flex-direction: column;
  }

  &.center {
    align-items: center;
  }

  @media (max-width: 1100px) {
      display: block;
      max-width: 600px;
      margin: 32px auto 0;
  }
}

.hide {
  display: none;
}

.green {
  color: #1ba49c;
}

.mt10 {
  margin-top: 10px;
}

.no-scroll {
  overflow: hidden;
}

@media (max-width: 750px) {
  h2 {
    font-size: 1.66rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}