.nav {
    width: 100%;

    nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        max-width: 1400px;
        margin: auto;
        padding: 10px 20px;

        h1 {
            img {
                width: 120px;
                border-radius: 4px;

                @media (max-width: 750px) {
                    width: 80px;
                }
            }
        }

        .wrap {
            .btn {
                margin: 0 10px;
                padding: 6px 18px;
            }

            .navlink {
                color: #000;
                font-size: 20px;
                margin: 0 20px;

                &:hover, &.active {
                    color: #1ba49c;
                }
            }
        }

        .toggler {
            display: none;
            padding: 15px 5px;
            width: 40px;
            margin-left: auto;
            cursor: pointer;
            z-index: 2;
        }

        .hamburger {
            cursor: pointer;
            width: 100%;

            .line {
                position: relative;
                width: 100%;
                height: 2px;
                background-color: #000;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-transition: all 0.6s ease;
                transition: all 0.6s ease;
    
                &:before, &:after {
                    content: '';
                    position: absolute;
                    top: -8px;
                    width: 100%;
                    height: 2px;
                    background: inherit;
                }
    
                &:after {
                    top: 8px;
                }
            }
        }

        // turn lines in X
        .hamburger.active {
            -webkit-transform: rotate(135deg);
                    transform: rotate(135deg);

            .line {
                background-color: #141313;
                &:before, &:after {
                    top: 0;
                    -webkit-transform: rotate(90deg);
                            transform: rotate(90deg);
                }
            }
        }

        @media (max-width: 750px) {
            .wrap {
                display: none;
            }
            .toggler {
                display: block;
            }
        }
    }

    #mobile-menu {
        width: 100%;

        nav {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-around;
            max-width: 1400px;
            margin: auto;
            padding: 10px;
        }

        .btn {
            font-size: 1rem;
            padding: 6px 18px;
            width: 106px;
        }

        .navlink {
            width: 106px;
            color: #000;
            &:hover, &.active {
                color: #1ba49c;
            }

            @media (max-width: 400px){
                width: auto;
            }
        }


        @media(min-width: 751px) {
            display: none;
        }
    }
}